export default {
  BUTTON_PRIMARY_CLASS: 'cm_button btn-fill-out',

  SEARCH_BOX_PLACEHOLDER: 'Search our store',

  PRODUCT_REPEATER_CLASS: 'row shop_container',

  GARAGE_SIZE: '<span class="garage-size cart_count bigcounter" key="garage-size">{{size}}</span>',

  SELECT_YOUR_VEHICLE_BLOCK:
    '<span class="cm_title">Select By Vehicle</span><span class="cm_subtitle">Filter your results by Vehicle to make sure you only see components that fit.</span>',

  START_OVER: 'Clear all',
};
