
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'col-lg-3 col-md-4 col-12 product-item' }, _createElement('div', { 'className': 'product default-align' }, _createElement('div', { 'className': 'product_img' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'popup_cart_image lazyload',
                    'src': this.resizeImage(this.image),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image')
                })), _createElement('div', { 'className': 'product_action_box' }, _createElement('ul', { 'className': 'list_none pr_action_btn' }, _createElement('li', { 'className': 'add-to-cart' }, !this.out_of_stock && !this.has_options ? _createElement('a', {
                    'href': 'javascript:void(0);',
                    'onClick': () => Shopify.addItem(this.variant_ids[0], 1),
                    'className': 'ajax-spin-cart p-cart-btn',
                    'key': '472'
                }, _createElement('span', { 'className': 'cart-title' }, _createElement('i', { 'className': 'icon-basket-loaded' }))) : null, this.has_options && !this.out_of_stock ? _createElement('a', {
                    'href': this.url,
                    'className': 'p-cart-btn',
                    'key': '793'
                }, _createElement('span', { 'className': 'cart-text' }, _createElement('i', { 'className': 'icon-settings' }))) : null, this.out_of_stock ? _createElement('button', {
                    'className': 'cart-disable p-cart-btn',
                    'key': '1022'
                }, _createElement('span', { 'className': 'cart-text' }, _createElement('i', { 'className': 'icon-ban' }))) : null), _createElement('li', {}, _createElement('a', {
                    'href': '#',
                    'data-placement': 'left',
                    'className': 'compare popup-ajax',
                    'data-pid': this.handle
                }, _createElement('i', { 'className': 'icon-shuffle' }))), _createElement('li', {}, _createElement('a', {
                    'onClick': () => window.quiqview(this.handle),
                    'data-toggle': 'modal',
                    'data-target': '#exampleModal',
                    'href': 'javascript:void(0);',
                    'className': 'popup-ajax'
                }, _createElement('i', { 'className': 'icon-magnifier-add' })))))), _createElement('div', { 'className': 'product_info' }, _createElement('h6', { 'className': 'product_title popup_cart_title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product_price' }, _createElement('span', { 'className': 'price' }, this.formatPrice(this.price)), this.compare_at_price > this.price ? [
                    _createElement('del', { 'key': '19971' }, this.formatPrice(this.compare_at_price)),
                    _createElement('div', {
                        'className': 'on_sale',
                        'key': '19973'
                    }, _createElement('span', { 'className': 'percent-count' }, '\n              -', Math.round((this.compare_at_price - this.price) * 100 / this.compare_at_price), '%\n            '))
                ] : null), _createElement('div', { 'className': 'pr_desc' }, this.wheel_offset ? [
                    ' Offset: ',
                    this.wheel_offset,
                    ' | '
                ] : null, this.wheel_bolt_pattern ? [
                    ' Bolt pattern: ',
                    this.wheel_bolt_pattern,
                    ' | '
                ] : null, this.wheel_finish ? [
                    ' Finish: ',
                    this.wheel_finish,
                    ' | '
                ] : null, this.wheel_width ? [
                    ' Width: ',
                    this.wheel_width,
                    ' | '
                ] : null, this.wheel_diameter ? [
                    ' Diameter: ',
                    this.wheel_diameter,
                    ' | '
                ] : null, this.wheel_bore ? [
                    ' Centerbore: ',
                    this.wheel_bore,
                    ' | '
                ] : null), _createElement('div', { 'className': 'list_product_action_box' }, _createElement('ul', { 'className': 'list_none pr_action_btn' }, _createElement('li', { 'className': 'add-to-cart' }, !this.out_of_stock && !this.has_options ? _createElement('a', {
                    'href': 'javascript:void(0);',
                    'onClick': () => Shopify.addItem(this.variant_ids[0], 1),
                    'className': 'ajax-spin-cart p-cart-btn',
                    'key': '3107'
                }, _createElement('span', { 'className': 'cart-title' }, _createElement('i', { 'className': 'icon-basket-loaded' }))) : null, !this.out_of_stock && this.has_options ? _createElement('a', {
                    'href': this.url,
                    'className': 'p-cart-btn cart-text',
                    'key': '3428'
                }, _createElement('i', { 'className': 'icon-settings' })) : null, this.out_of_stock ? _createElement('span', {
                    'className': 'p-cart-btn cart-text',
                    'key': '3604'
                }, _createElement('i', { 'className': 'icon-ban' }), '\n              Sold out\n            ') : null), _createElement('li', {}, _createElement('a', {
                    'href': '#',
                    'data-placement': 'left',
                    'className': 'compare popup-ajax',
                    'data-pid': this.handle
                }, _createElement('i', { 'className': 'icon-shuffle' }))), _createElement('li', {}, _createElement('a', {
                    'onClick': () => window.quiqview(this.handle),
                    'data-toggle': 'modal',
                    'data-target': '#exampleModal',
                    'href': 'javascript:void(0);',
                    'className': 'popup-ajax'
                }, _createElement('i', { 'className': 'icon-magnifier-add' }))))), _createElement('div', { 'className': 'cm_view-on-vehicle-btn-container' }, this.wheel_mpn ? _createElement('button', {
                    'className': 'cm_view-on-vehicle-btn',
                    'onClick': () => window.Convermax.openVisualizationDialog(this.wheel_mpn),
                    'key': '4290'
                }, _createElement('i', { 'className': 'icon-camera' }), ' View On Vehicle\n        ') : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []