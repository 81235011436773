//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-244:_9184,_8632,_1904,_8284,_6280,_6836,_1668,_5028;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-244')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-244', "_9184,_8632,_1904,_8284,_6280,_6836,_1668,_5028");
        }
      }catch (ex) {
        console.error(ex);
      }